import React, { PureComponent, Fragment } from 'react';
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import history from '../../history';
import validateLogin from '../../Validations/Login';
import { toast } from "react-toastify";
// import validateSignup from '../../Validations/Registration';
import { userLoginRequest, logout } from '../../Store/actions/loginActions';
// import { userSignupRequest } from '../../Store/actions/signupActions';
// import AlertWrapper from '../Common/AlertWrapper';
// import AlertWrapperSuccess from '../Common/AlertWrapperSuccess';
// import AlertWrapperWarning from '../Common/AlertWrapperWarning';
// import "../../Assets/css/captcha.css";

import { AJAX_REQUEST, REMOVE_STORAGE, USER, GET_COOKIE, GET_STORAGE, TITLE } from '../../Constants/AppConstants';


class Login extends PureComponent {
    constructor(props) {
        super(props);

        // let settings = '';
        // if (GET_STORAGE('settings')) {
        //     settings = JSON.parse(GET_STORAGE('settings'));
        // }

        this.state = {
            user_email: '',
            user_password: '',
            loading: true,
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true
        }
        document.title = "Login - " + TITLE;
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    // changeRememberHandler = (e) => {
    //     if (document.getElementById("remember").checked) {
    //         this.setState({
    //             remember: 'checked'
    //         });
    //     } else {
    //         this.setState({
    //             remember: ''
    //         });
    //     }
    // }

    // Captcha = () => {
    //     let alpha = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    //         'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
    //         '0', '1', '2', '3', '4', '5', '6', '7', '8', '9');
    //     let i = 0;
    //     let a = '';
    //     let b = '';
    //     let c = '';
    //     let d = '';
    //     let e = '';
    //     let f = '';
    //     let g = '';
    //     for (i = 0; i < 6; i++) {
    //         a = alpha[Math.floor(Math.random() * alpha.length)];
    //         b = alpha[Math.floor(Math.random() * alpha.length)];
    //         c = alpha[Math.floor(Math.random() * alpha.length)];
    //         d = alpha[Math.floor(Math.random() * alpha.length)];
    //         e = alpha[Math.floor(Math.random() * alpha.length)];
    //         f = alpha[Math.floor(Math.random() * alpha.length)];
    //         g = alpha[Math.floor(Math.random() * alpha.length)];
    //     }
    //     let code = a + ' ' + b + ' ' + ' ' + c + ' ' + d + ' ' + e + ' ' + f + ' ' + g;
    //     this.setState({
    //         captchaCode: code
    //     });
    // }

    async componentDidMount() {
        document.querySelector("body").scrollIntoView();
        const cur_storage = await JSON.parse(GET_STORAGE(USER));
        if (cur_storage) {
            history.push('/profile');
        }
        this.setState({
            loading: false
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const form_data = {
            user_email: this.state.user_email,
            user_password: this.state.user_password,
            // device_uuid: '2f28b50fc9f863b3'
        }
        const val_return = validateLogin(form_data);
        this.setState(val_return);
        if (val_return.isValid) {
            this.setState({ errors: {}, isLoading: true });
            this.props.userLoginRequest(form_data).then(results => {
                if (results.type === "success") {
                    toast.success(results.success ? results.success : results.message.bangla)
                    const url = new URL(window.location.href);
                    console.log('url', url);
                    const redirect = url.searchParams.get("redirect");
                    console.log('redirect', redirect);
                    if (redirect) {
                        history.push('/' + redirect)
                    } else {
                        history.push('/profile');
                    }
                } else {
                    // if (res.messages && Array.isArray(res.messages) && (res.messages.length > 0)) {
                    //     res.messages.map((messages_single, messages_index) => {
                    //         toast.error(messages_single)
                    //     })
                    // }
                    toast.error(results.error ? results.error : results.message.bangla)
                    this.setState({
                        server_message: results.message,
                        isLoading: false,
                        isFormValid: false
                    });
                }
            }
            );
        } else {
            Object.entries(val_return.errors).map(([key, value]) => {
                toast.error(value)
            })
        }
    }

    /*onSubmitR = (e) => {
        e.preventDefault();
        const val_return = validateSignup(this.state);
        this.setState(val_return);

        if (val_return.isValid) {
            if (this.state.affiliate_code !== '') {
                let data = {
                    email: this.state.reg_email,
                    password: this.state.reg_password,
                    role: this.state.role,
                    affiliate_code: this.state.affiliate_code
                };
                // data.append('email', this.state.reg_email);
                // data.append('password', this.state.reg_password);
                // data.append('role', this.state.role);

                this.setState({ errors: {}, isLoadingR: true });
                this.props.userSignupRequest(data).then(results => {
                    if (results.response.code === 1000) {
                        this.setState({
                            server_message: results.response.message,
                            isLoadingR: false,
                            isFormValid: false,
                            captcha_input: ''
                        });
                        history.push('/checkout');
                    } else {
                        this.setState({
                            server_message: results.response.message,
                            isLoadingR: false,
                            isFormValid: false
                        });
                    }
                }
                );
            } else {
                this.setState({
                    server_message: '<strong>Sorry</strong> You are not allowed to make any purchase on this site. To be able purchase, you must use the referral link from any of our affiliate.',
                    isLoadingR: false,
                    isFormValid: false
                });
            }
        }
    }*/

    render() {
        const { server_message, success_alert_wrapper_show, errors, isLoading, warning_wrapper_show, captchaCode } = this.state;
        const errors_data = server_message;

        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <React.Fragment>
                            <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                    <main className="auth-section">
                                        {/* <AlertWrapper errors_data={errors_data} isFormValid={this.state.isFormValid} />
                                        <AlertWrapperSuccess errors_data={errors_data} success_alert_wrapper_show={success_alert_wrapper_show} /> */}
                                        <div className="para-title text-center">লগিন করুন</div>
                                        <div className="auth-section-inner">
                                            <form className="action_form" onSubmit={this.onSubmit}>
                                                <div className="form-group">
                                                    <input type="email" className={classnames("form-control", { 'pl_error_input': errors.user_email })} name="user_email" id="user_email" value={this.state.user_email} onChange={this.changeHandler} placeholder="আপনার ইমেইল লিখুন" />
                                                </div>
                                                <div className="form-group">
                                                    <input className={classnames("form-control", { 'pl_error_input': errors.user_password })} type="password" name="user_password" id="user_password" value={this.state.user_password} onChange={this.changeHandler} placeholder="পাসওয়ার্ড লিখুন" />
                                                    <div className="lost-password">
                                                        <NavLink activeClassName='active' to="/password-reset"> পাসওয়ার্ড ভুলে গিয়েছেন? </NavLink>
                                                    </div>
                                                </div>
                                                <div className="form-group auth-button-group text-center">
                                                    <button type="submit" disabled={this.state.isLoading} className="auth-button" name="login" value="লগিন">{this.state.isLoading ? 'অপেক্ষা করুন...' : 'লগিন'}</button>
                                                </div>
                                                <div className="register-link text-center">
                                                    একাউন্ট খোলা নেই? <NavLink activeClassName='active' to="/registration">  রেজিস্ট্রশন করুন</NavLink>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="clearfix"></div>
                                    </main>
                                </div>

                            </div>
                        </React.Fragment>
                }
            </Fragment>
        );
    }
}

Login.propTypes = {
    userLoginRequest: PropTypes.func.isRequired,
    // userSignupRequest: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated
    }
}

// export default connect(mapStateToProps, { logout })(Login);

export default connect(mapStateToProps, { userLoginRequest, logout })(Login);