import React, { PureComponent, Fragment } from 'react';
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import history from '../../history';
import { logout } from '../../Store/actions/loginActions';
import validateLogin from '../../Validations/Login';
import validateSignup from '../../Validations/Registration';
import { userLoginRequest } from '../../Store/actions/loginActions';
import { userSignupRequest } from '../../Store/actions/signupActions';
import AlertWrapper from '../Common/AlertWrapper';
import AlertWrapperSuccess from '../Common/AlertWrapperSuccess';
import AlertWrapperWarning from '../Common/AlertWrapperWarning';
// import "../../Assets/css/captcha.css";
import { toast } from "react-toastify";

import { AJAX_REQUEST, REMOVE_STORAGE, DELETE_LOGIN_COOKIE, USER, GET_COOKIE, GET_STORAGE, TITLE } from '../../Constants/AppConstants';


class Login extends PureComponent {
    constructor(props) {
        super(props);

        let settings = '';
        if (GET_STORAGE('settings')) {
            settings = JSON.parse(GET_STORAGE('settings'));
        }

        this.state = {
            name: '',
            email: '',
            phone: '',
            showNameEditForm: false,
            showPhoneEditForm: false,
            nameBtnLoading: false,
            phoneBtnLoading: false,
            loading: true,
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
            server_message: '',
        }
        document.title = "Profile - " + TITLE;
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    changeRememberHandler = (e) => {
        if (document.getElementById("remember").checked) {
            this.setState({
                remember: 'checked'
            });
        } else {
            this.setState({
                remember: ''
            });
        }
    }

    async componentDidMount() {
        document.querySelector("body").scrollIntoView();
        const cur_storage = await JSON.parse(GET_STORAGE(USER));
        if (!cur_storage) {
            history.push('/login');
        } else {
            this.getUserProfileV2();
        }
    }

    getUserProfileV2 = () => {
        const request_result = AJAX_REQUEST("POST", "getUserProfileV2", {});
        request_result.then(results => {
            if (results.type === "success") {
                this.setState({
                    name: results.data.name,
                    email: results.data.email,
                    phone: results.data.phone,
                    loading: false
                });
            } else {
                toast.error(results.error ? results.error : results.message.bangla)
                this.setState({
                    loading: false
                });
            }
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const val_return = validateLogin(this.state);
        this.setState(val_return);
        if (val_return.isValid) {
            this.setState({ errors: {}, isLoading: true });
            this.props.userLoginRequest(this.state).then(results => {
                if (results.response.code === 1000) {
                    if (Object.values(results.response.data.roles).includes('customer')) {

                        let settings = null;
                        if (GET_STORAGE("settings")) {
                            settings = JSON.parse(GET_STORAGE("settings"));
                        }

                        let meal_menu_active = false;
                        if (settings && settings.meal_menu_public == "yes") {
                            meal_menu_active = true;
                        } else {
                            if (results.response.data.meal_menu_activated) {
                                meal_menu_active = true;
                            }
                        }

                        if (results.response.data.hasOwnProperty('site')) {
                            if (results.response.data.site == 'refer') {
                                if (meal_menu_active) {
                                    // window.location.href = REFER_URL + 'serviceLogin?token=' + results.response.data.token + '&redirect=meals';
                                } else {
                                    // window.location.href = REFER_URL + 'serviceLogin?token=' + results.response.data.token;
                                }
                            } else {
                                // if (Number(MEAL_COUNT()) + Number(ITEM_COUNT()) > 0) {
                                //     history.push('/checkout');
                                // } else {
                                //     if (meal_menu_active) {
                                //         history.push('/meals');
                                //     } else {
                                //         history.push('/');
                                //     }
                                // }
                            }
                        } else {
                            // if (Number(MEAL_COUNT()) + Number(ITEM_COUNT()) > 0) {
                            //     history.push('/checkout');
                            // } else {
                            //     if (meal_menu_active) {
                            //         history.push('/meals');
                            //     } else {
                            //         history.push('/');
                            //     }
                            // }
                        }

                        // if(history.goBack(1)){
                        //     history.goBack(1);
                        // }else{
                        // history.push('/');
                        // }
                        // }else if(results.response.data.role === 'customer'){
                        //     window.location.href = CUSTOMER_URL+'serviceLogin?token='+results.response.data.token;
                    } else {
                        this.props.logout();
                        REMOVE_STORAGE(USER);
                        this.setState({
                            // server_message: results.response.message,
                            server_message: "Unauthorized Access",
                            isLoading: false,
                            isFormValid: false
                        });
                    }
                } else {
                    this.setState({
                        server_message: results.response.message,
                        isLoading: false,
                        isFormValid: false
                    });
                }
            }
            );
        }
    }

    onSubmitR = (e) => {
        e.preventDefault();
        const val_return = validateSignup(this.state);
        this.setState(val_return);

        if (val_return.isValid) {
            if (this.state.affiliate_code !== '') {
                let data = {
                    email: this.state.reg_email,
                    password: this.state.reg_password,
                    role: this.state.role,
                    affiliate_code: this.state.affiliate_code
                };
                // data.append('email', this.state.reg_email);
                // data.append('password', this.state.reg_password);
                // data.append('role', this.state.role);

                this.setState({ errors: {}, isLoadingR: true });
                this.props.userSignupRequest(data).then(results => {
                    if (results.response.code === 1000) {
                        this.setState({
                            server_message: results.response.message,
                            isLoadingR: false,
                            isFormValid: false,
                            captcha_input: ''
                        });
                        history.push('/checkout');
                    } else {
                        this.setState({
                            server_message: results.response.message,
                            isLoadingR: false,
                            isFormValid: false
                        });
                    }
                }
                );
            } else {
                this.setState({
                    server_message: '<strong>Sorry</strong> You are not allowed to make any purchase on this site. To be able purchase, you must use the referral link from any of our affiliate.',
                    isLoadingR: false,
                    isFormValid: false
                });
            }
        }
    }

    saveNameChange = (e) => {
        e.preventDefault()
        if (!this.state.nameBtnLoading) {
            const name = this.state.name
            if (name.length == 0) {
                toast.error('Name field is required')
            } else {
                this.setState({ nameBtnLoading: true })
                const request_result = AJAX_REQUEST("POST", "updateUserProfileV2", {
                    name: name
                });
                request_result.then(results => {
                    if (results.type === "success") {
                        toast.success(results.success ? results.success : results.message.bangla)
                        this.setState({ nameBtnLoading: false, showNameEditForm: false })
                    } else {
                        toast.error(results.error ? results.error : results.message.bangla)
                        this.setState({ nameBtnLoading: false })
                    }
                });
            }
        }
    }

    savePhoneChange = (e) => {
        e.preventDefault()
        if (!this.state.phoneBtnLoading) {
            const phone = this.state.phone
            if (!phone) {
                toast.error('Phone field is required')
            } else {
                this.setState({ phoneBtnLoading: true })
                const request_result = AJAX_REQUEST("POST", "updateUserProfileV2", {
                    phone: phone
                });
                request_result.then(results => {
                    if (results.type === "success") {
                        toast.success(results.success ? results.success : results.message.bangla)
                        this.setState({ phoneBtnLoading: false, showPhoneEditForm: false })
                    } else {
                        toast.error(results.error ? results.error : results.message.bangla)
                        this.setState({ phoneBtnLoading: false })
                    }
                });
            }
        }
    }

    logout = (e) => {
        e.preventDefault()
        this.props.logout();
        REMOVE_STORAGE(USER);
        DELETE_LOGIN_COOKIE();
    }

    render() {
        const { server_message, success_alert_wrapper_show, errors, isLoading, warning_wrapper_show, captchaCode } = this.state;
        const errors_data = server_message;

        // if (this.props.isAuthenticated) {
        //     history.push('/');
        // }

        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading section-loading"> <i class="fas fa-spinner fa-spin"></i></div>
                        :
                        <React.Fragment>
                            <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                    <main className="auth-section">
                                        {/* <AlertWrapper errors_data={errors_data} isFormValid={this.state.isFormValid} />
                                        <AlertWrapperSuccess errors_data={errors_data} success_alert_wrapper_show={success_alert_wrapper_show} /> */}
                                        <div className="para-title text-center">আমার প্রোফাইল</div>
                                        <div className="auth-section-inner">

                                            <form className="action_form" onSubmit={this.onSubmit}>
                                                <div className="auth-profile">
                                                    <ul>
                                                        <li>
                                                            <label>নাম</label>
                                                            {
                                                                this.state.showNameEditForm ?
                                                                    <Fragment>
                                                                        <input type="text" value={this.state.name} onChange={this.changeHandler} name="name" className="form-control" placeholder="example name" />
                                                                        <a className="auth-profile-save" href="#" onClick={this.saveNameChange}>সেভ</a>
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        {this.state.name}
                                                                        <a className="auth-profile-edit" href="#" onClick={(e) => { e.preventDefault(); this.setState({ showNameEditForm: true }) }}><i class="far fa-edit"></i></a>
                                                                    </Fragment>
                                                            }
                                                        </li>
                                                        <li>
                                                            <label>আমার ইমেইল</label>
                                                            {this.state.email}
                                                        </li>

                                                        <li>
                                                            <label>আমার মোবাইল নম্বর</label>
                                                            {
                                                                this.state.showPhoneEditForm ?
                                                                    <Fragment>
                                                                        <input type="text" value={this.state.phone} onChange={this.changeHandler} name="phone" className="form-control" placeholder="01xxxxxxxxx" />
                                                                        <a className="auth-profile-save" href="#" onClick={this.savePhoneChange}>সেভ</a>
                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        {this.state.phone}
                                                                        <a className="auth-profile-edit" href="#" onClick={(e) => { e.preventDefault(); this.setState({ showPhoneEditForm: true }) }}><i class="far fa-edit"></i></a>
                                                                    </Fragment>
                                                            }
                                                        </li>
                                                    </ul>

                                                </div>
                                                <div className="profile-change-password text-center">
                                                    <NavLink to="/change-password">পাসওয়ার্ড পরিবর্তন</NavLink>
                                                    {/* &nbsp;&nbsp;
                                                    <a href="/logout" onClick={this.logout}>লগ আউট</a> */}
                                                </div>
                                            </form>
                                        </div>
                                        <div className="clearfix"></div>
                                    </main>
                                </div>

                            </div>
                        </React.Fragment>
                }
            </Fragment>
        );
    }
}

Login.propTypes = {
    userLoginRequest: PropTypes.func.isRequired,
    userSignupRequest: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated
    }
}

// export default connect(mapStateToProps, { logout })(Login);

export default connect(mapStateToProps, { userLoginRequest, userSignupRequest, logout })(Login);