import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';

class termsAndCondition extends PureComponent {

  scrollbars = React.createRef();

  render() {
    document.title = "Terms & Condition - Al Quran Technobd";
    return (
      <Fragment>
        <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
          <div className="col-sm-12 col-md-12">
            <div className="download-section">
              <div className="download-section-title">নীতিমালা</div>
              <div className="download-section-inner">
                <div className="nano nano-dua">
                  <div className="nano-content">
                    <div className="para-item download-item">
                      <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                        <div className="disclaimer">
                          <p><strong>1. Terms</strong></p>
                          <p>
                            By accessing our app, Al Quran, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree
                            with any of these terms, you are prohibited from using or accessing Al Quran. The materials contained in Al Quran are protected by applicable copyright and trademark law.
                                 </p>
                          <p><strong>2. Use License</strong></p>
                          <ul type="a">
                            <li>
                              Permission is granted to temporarily download one copy of Al Quran per device for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                                  </li>
                            <li>modify or copy the materials;</li>
                            <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                            <li>attempt to decompile or reverse engineer any software contained in Al Quran;</li>
                            <li>remove any copyright or other proprietary notations from the materials; or</li>
                            <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                            <li>
                              This license shall automatically terminate if you violate any of these restrictions and may be terminated by Technobd Limited at any time. Upon terminating your viewing of these materials or upon the termination of this
                              license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
            </li>
                          </ul>
                          <p><strong>3. Disclaimer</strong></p>
                          <ul type="a">
                            <li>
                              The materials within Al Quran are provided on an 'as is' basis. Technobd Limited makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied
                              warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
            </li>
                            <li>
                              Further, Technobd Limited does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any
                              sites linked to Al Quran.
            </li>
                          </ul>
                          <p><strong>4. Limitations</strong></p>
                          <p>
                            In no event shall Technobd Limited or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use Al
                            Quran, even if Technobd Limited or a Technobd Limited authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties,
                            or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
               </p>
                          <p><strong>5. Accuracy of materials</strong></p>
                          <p>
                            The materials appearing in Al Quran could include technical, typographical, or photographic errors. Technobd Limited does not warrant that any of the materials on Al Quran are accurate, complete or current. Technobd Limited may
                            make changes to the materials contained in Al Quran at any time without notice. However Technobd Limited does not make any commitment to update the materials.
        </p>
                          <p><strong>6. Links</strong></p>
                          <p>
                            Technobd Limited has not reviewed all of the sites linked to its app and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Technobd Limited of the site. Use of
                            any such linked website is at the user's own risk.
        </p>
                          <p><strong>7. Modifications</strong></p>
                          <p>Technobd Limited may revise these terms of service for its app at any time without notice. By using Al Quran you are agreeing to be bound by the then current version of these terms of service.</p>
                          <p><strong>8. Governing Law</strong></p>
                          <p>These terms and conditions are governed by and construed in accordance with the laws of Dhaka, Bangladesh and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>



                        </div>
                      </Scrollbars>
                    </div>

                  </div>
                </div>
              </div>

            </div>

            <div>
            </div>

          </div>
        </div>

      </Fragment>
    );
  }
}

export default withRouter(termsAndCondition);