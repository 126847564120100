import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';

class ParaKrom extends PureComponent {

    scrollbars = React.createRef();


    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            player_loading: true,
            kalima_loading: true,
            error: '',
            kalimaList: [],

            activeKalima: '',
            currently_playing_kalima_sl: '',
            currently_playing_kalima_name_bn: '',
            currently_playing_kalima_name_bn_sort: '',
            currently_playing_kalima_name_en: '',
            currently_playing_kalima_no_bn: '',
            currently_playing_prev_kalima_data: '',
            currently_playing_kalima_data: '',
            currently_playing_next_kalima_data: '',
            currently_playing_mode: "a",
            playing_mode: "a",

            //player state
            url: null,
            pip: false,
            playing: false,
            controls: false,
            light: false,
            volume: 0.8,
            muted: false,
            played: 0,
            loaded: 0,
            duration: 0,
            playbackRate: 1.0,
            loop: false
        }
        document.title = "Kalima List - Al Quran Technobd";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        this.setState({
            loading: false
        });
        this.getkalimaList();
    }

    updateActiveKalima = (value) => {
        const activeKalima = this.state.kalimaList.filter((kalimaLS, index) => {
            return kalimaLS.sl == value;
        })
        if (activeKalima && (activeKalima.length > 0)) {
            this.load(activeKalima[0].audio_url, activeKalima[0].sl, false)
        }
    }

    getkalimaList = () => {
        this.setState({ kalima_loading: true });
        AJAX_PUBLIC_REQUEST("POST", "getAllKalima", {}).then(results => {
            if (results.type === "success") {
                const kalima = results.data;
                this.setState({
                    kalimaList: kalima,
                    kalima_loading: false,
                });
                if (kalima.length > 0) {
                    const first_kalima_no = kalima[0];
                    if (first_kalima_no && first_kalima_no.sl) {
                        this.setState({ activeKalima: first_kalima_no.sl, player_loading: false });
                        if (kalima.length > 1) {
                            this.setState({ currently_playing_next_kalima_data: kalima[1] });
                        }
                        this.load(first_kalima_no.audio_url, first_kalima_no.sl, false)
                    }
                }
            } else {
                this.setState({
                    error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
                    kalima_loading: false,
                    player_loading: false
                })
            }
        });
    }

    // player functions
    load = async (url, kalima_sl, playing = true) => {
        let activeKalima = parseInt(kalima_sl);
        let currently_playing_kalima_sl = parseInt(kalima_sl);
        let currently_playing_kalima_name_bn = '';
        let currently_playing_kalima_name_bn_sort = '';
        let currently_playing_kalima_name_en = '';
        let currently_playing_kalima_no_bn = '';
        let currently_playing_prev_kalima_data = '';
        let currently_playing_kalima_data = '';
        let currently_playing_next_kalima_data = '';

        const prev_kalima_sl = parseInt(kalima_sl) - 1;
        const next_kalima_sl = parseInt(kalima_sl) + 1;


        if (this.state.kalimaList.length > 0) {
            await this.state.kalimaList.map((kalimaList_single, index) => {
                if (parseInt(kalimaList_single.sl) === parseInt(kalima_sl)) {
                    currently_playing_kalima_data = kalimaList_single;
                    currently_playing_kalima_name_bn = currently_playing_kalima_data.title_bn;
                    currently_playing_kalima_name_bn_sort = currently_playing_kalima_data.title_bn_short;
                    currently_playing_kalima_name_en = currently_playing_kalima_data.title_bn;
                    currently_playing_kalima_no_bn = currently_playing_kalima_data.sl_bn;
                }
                if (parseInt(kalimaList_single.sl) === prev_kalima_sl) {
                    currently_playing_prev_kalima_data = kalimaList_single;
                }
                if (parseInt(kalimaList_single.sl) === next_kalima_sl) {
                    currently_playing_next_kalima_data = kalimaList_single;
                }
            })
        }

        this.setState({
            url,
            played: 0,
            loaded: 0,
            pip: false,
            playing: playing,
            activeKalima: activeKalima,
            currently_playing_kalima_sl: currently_playing_kalima_sl,
            currently_playing_kalima_name_bn: currently_playing_kalima_name_bn,
            currently_playing_kalima_name_bn_sort: currently_playing_kalima_name_bn_sort,
            currently_playing_kalima_name_en: currently_playing_kalima_name_en,
            currently_playing_kalima_no_bn: currently_playing_kalima_no_bn,
            currently_playing_prev_kalima_data: currently_playing_prev_kalima_data,
            currently_playing_kalima_data: currently_playing_kalima_data,
            currently_playing_next_kalima_data: currently_playing_next_kalima_data
        })
    }

    handlePlayPause = () => {
        this.setState({ playing: !this.state.playing })
    }

    handleStop = () => {
        this.setState({ url: null, playing: false })
    }

    handleToggleControls = () => {
        const url = this.state.url
        this.setState({
            controls: !this.state.controls,
            url: null
        }, () => this.load(url))
    }

    handleToggleLight = () => {
        this.setState({ light: !this.state.light })
    }

    handleToggleLoop = () => {
        this.setState({ loop: !this.state.loop })
    }

    handleVolumeChange = e => {
        this.setState({ volume: parseFloat(e.target.value) })
    }

    handleToggleMuted = () => {
        this.setState({ muted: !this.state.muted })
    }

    // handleSetPlaybackRate = e => {
    //     this.setState({ playbackRate: parseFloat(e.target.value) })
    // }
    handleSetPlaybackRate = (e, value) => {
        e.preventDefault();
        this.setState({ playbackRate: parseFloat(value) })
    }

    handleTogglePIP = () => {
        this.setState({ pip: !this.state.pip })
    }

    handlePlay = () => {
        console.log('onPlay')
        this.setState({ playing: true })
    }

    handleEnablePIP = () => {
        console.log('onEnablePIP')
        this.setState({ pip: true })
    }

    handleDisablePIP = () => {
        console.log('onDisablePIP')
        this.setState({ pip: false })
    }

    handlePause = () => {
        console.log('onPause')
        this.setState({ playing: false })
    }

    handleSeekMouseDown = e => {
        this.setState({ seeking: true })
    }

    handleSeekChange = e => {
        this.setState({ played: parseFloat(e.target.value) })
    }

    handleSeekMouseUp = e => {
        this.setState({ seeking: false })
        this.player.seekTo(parseFloat(e.target.value))
    }

    handleProgress = state => {
        console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    handleEnded = async () => {
        console.log('onEnded')
        // const currently_playing_kalima_no = this.state.currently_playing_kalima_no;
        const currently_playing_kalima_data = this.state.currently_playing_kalima_data;
        const next_kalima_data = this.state.currently_playing_next_kalima_data;
        const currently_playing_mode = this.state.currently_playing_mode;
        const playing_mode = this.state.playing_mode;
        if ((playing_mode == 'ab') && (currently_playing_mode == 'a')) {
            if (currently_playing_kalima_data != '') {
                this.setState({ currently_playing_mode: 'b' });
                // this.load(currently_playing_kalima_data.audio_url, currently_playing_kalima_data.sl);
            }
        } else if ((playing_mode == 'ab') && (currently_playing_mode == 'b')) {
            if (next_kalima_data != '') {
                this.setState({ currently_playing_mode: 'a' });
                // this.load(next_kalima_data.audio_url, next_kalima_data.sl);
                console.log('next_kalima_data', next_kalima_data)
            }
        } else if (playing_mode == 'a') {
            if (next_kalima_data != '') {
                this.setState({ currently_playing_mode: 'a' });
                // this.load(next_kalima_data.audio_url, next_kalima_data.sl);
                console.log('next_kalima_data', next_kalima_data)
            }
        } else {
            if (next_kalima_data != '') {
                this.setState({ currently_playing_mode: 'b' });
                // this.load(next_kalima_data.audio_url, next_kalima_data.sl);
                console.log('next_kalima_data', next_kalima_data)
            }
        }
        this.setState({ playing: this.state.loop })
    }

    handleDuration = (duration) => {
        console.log('onDuration', duration)
        this.setState({ duration })
    }

    handleClickFullscreen = () => {
        screenfull.request(findDOMNode(this.player))
    }

    renderLoadButton = (url, label) => {
        return (
            <button onClick={() => this.load(url)}>
                {label}
            </button>
        )
    }

    ref = player => {
        this.player = player
    }


    //custom playing action
    changePlayingMode = (e, value) => {
        e.preventDefault();
        this.setState({ playing_mode: value })
    }


    render() {

        const { url, playing, controls, light, volume, muted, loop, played, loaded, duration, playbackRate, pip } = this.state
        const SEPARATOR = ' · '

        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className="loading container full_page_loader"><i class="fas fa-spinner fa-spin"></i></div>
                        :
                        <Fragment>

                            {/* player wrapper start */}
                            <div style={{ display: 'none' }}>
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        ref={this.ref}
                                        className='react-player'
                                        width='100%'
                                        height='100%'
                                        url={url}
                                        pip={pip}
                                        playing={playing}
                                        controls={controls}
                                        light={light}
                                        loop={loop}
                                        playbackRate={playbackRate}
                                        volume={volume}
                                        muted={muted}
                                        onReady={() => console.log('onReady')}
                                        onStart={() => console.log('onStart')}
                                        onPlay={this.handlePlay}
                                        onEnablePIP={this.handleEnablePIP}
                                        onDisablePIP={this.handleDisablePIP}
                                        onPause={this.handlePause}
                                        onBuffer={() => console.log('onBuffer')}
                                        onSeek={e => console.log('onSeek', e)}
                                        onEnded={this.handleEnded}
                                        onError={e => console.log('onError', e)}
                                        onProgress={this.handleProgress}
                                        onDuration={this.handleDuration}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Controls</th>
                                                    <td>
                                                        <button onClick={this.handleStop}>Stop</button>
                                                        <button onClick={this.handlePlayPause}>{playing ? 'Pause' : 'Play'}</button>
                                                        <button onClick={this.handleClickFullscreen}>Fullscreen</button>
                                                        {light &&
                                                            <button onClick={() => this.player.showPreview()}>Show preview</button>}
                                                        {ReactPlayer.canEnablePIP(url) &&
                                                            <button onClick={this.handleTogglePIP}>{pip ? 'Disable PiP' : 'Enable PiP'}</button>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Speed</th>
                                                    <td>
                                                        <button onClick={this.handleSetPlaybackRate} value={1}>1x</button>
                                                        <button onClick={this.handleSetPlaybackRate} value={1.5}>1.5x</button>
                                                        <button onClick={this.handleSetPlaybackRate} value={2}>2x</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Seek</th>
                                                    <td>
                                                        <input
                                                            type='range' min={0} max={0.999999} step='any'
                                                            value={played}
                                                            onMouseDown={this.handleSeekMouseDown}
                                                            onChange={this.handleSeekChange}
                                                            onMouseUp={this.handleSeekMouseUp}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Volume</th>
                                                    <td>
                                                        <input type='range' min={0} max={1} step='any' value={volume} onChange={this.handleVolumeChange} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <label htmlFor='controls'>Controls</label>
                                                    </th>
                                                    <td>
                                                        <input id='controls' type='checkbox' checked={controls} onChange={this.handleToggleControls} />
                                                        <em>&nbsp; Requires player reload</em>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <label htmlFor='muted'>Muted</label>
                                                    </th>
                                                    <td>
                                                        <input id='muted' type='checkbox' checked={muted} onChange={this.handleToggleMuted} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <label htmlFor='loop'>Loop</label>
                                                    </th>
                                                    <td>
                                                        <input id='loop' type='checkbox' checked={loop} onChange={this.handleToggleLoop} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <label htmlFor='light'>Light mode</label>
                                                    </th>
                                                    <td>
                                                        <input id='light' type='checkbox' checked={light} onChange={this.handleToggleLight} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Played</th>
                                                    <td><progress max={1} value={played} /></td>
                                                </tr>
                                                <tr>
                                                    <th>Loaded</th>
                                                    <td><progress max={1} value={loaded} /></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="col-md-4">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>YouTube</th>
                                                    <td>
                                                        {this.renderLoadButton('https://www.youtube.com/watch?v=oUFJJNQGwhk', 'Test A')}
                                                        {this.renderLoadButton('https://www.youtube.com/watch?v=jNgP6d9HraI', 'Test B')}
                                                        {this.renderLoadButton('https://www.youtube.com/playlist?list=PLogRWNZ498ETeQNYrOlqikEML3bKJcdcx', 'Playlist')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>SoundCloud</th>
                                                    <td>
                                                        {this.renderLoadButton('https://soundcloud.com/miami-nights-1984/accelerated', 'Test A')}
                                                        {this.renderLoadButton('https://soundcloud.com/tycho/tycho-awake', 'Test B')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Facebook</th>
                                                    <td>
                                                        {this.renderLoadButton('https://www.facebook.com/facebook/videos/10153231379946729/', 'Test A')}
                                                        {this.renderLoadButton('https://www.facebook.com/FacebookDevelopers/videos/10152454700553553/', 'Test B')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Vimeo</th>
                                                    <td>
                                                        {this.renderLoadButton('https://vimeo.com/90509568', 'Test A')}
                                                        {this.renderLoadButton('https://vimeo.com/169599296', 'Test B')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Twitch</th>
                                                    <td>
                                                        {this.renderLoadButton('https://www.twitch.tv/videos/106400740', 'Test A')}
                                                        {this.renderLoadButton('https://www.twitch.tv/videos/12783852', 'Test B')}
                                                        {this.renderLoadButton('https://www.twitch.tv/kronovi', 'Test C')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Streamable</th>
                                                    <td>
                                                        {this.renderLoadButton('https://streamable.com/moo', 'Test A')}
                                                        {this.renderLoadButton('https://streamable.com/ifjh', 'Test B')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Wistia</th>
                                                    <td>
                                                        {this.renderLoadButton('https://home.wistia.com/medias/e4a27b971d', 'Test A')}
                                                        {this.renderLoadButton('https://home.wistia.com/medias/29b0fbf547', 'Test B')}
                                                        {this.renderLoadButton('https://home.wistia.com/medias/bq6epni33s', 'Test C')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>DailyMotion</th>
                                                    <td>
                                                        {this.renderLoadButton('https://www.dailymotion.com/video/x5e9eog', 'Test A')}
                                                        {this.renderLoadButton('https://www.dailymotion.com/video/x61xx3z', 'Test B')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Mixcloud</th>
                                                    <td>
                                                        {this.renderLoadButton('https://www.mixcloud.com/mixcloud/meet-the-curators/', 'Test A')}
                                                        {this.renderLoadButton('https://www.mixcloud.com/mixcloud/mixcloud-curates-4-mary-anne-hobbs-in-conversation-with-dan-deacon/', 'Test B')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Vidyard</th>
                                                    <td>
                                                        {this.renderLoadButton('https://video.vidyard.com/watch/YBvcF2BEfvKdowmfrRwk57', 'Test A')}
                                                        {this.renderLoadButton('https://video.vidyard.com/watch/BLXgYCDGfwU62vdMWybNVJ', 'Test B')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Files</th>
                                                    <td>
                                                        {this.renderLoadButton('https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4', 'mp4')}
                                                        {this.renderLoadButton('https://test-videos.co.uk/vids/bigbuckbunny/webm/vp8/360/Big_Buck_Bunny_360_10s_1MB.webm', 'webm')}
                                                        {this.renderLoadButton('https://filesamples.com/samples/video/ogv/sample_640x360.ogv', 'ogv')}
                                                        {this.renderLoadButton('https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3', 'mp3')}
                                                        <br />
                                                        {this.renderLoadButton('https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8', 'HLS (m3u8)')}
                                                        {this.renderLoadButton('http://dash.edgesuite.net/envivio/EnvivioDash3/manifest.mpd', 'DASH (mpd)')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Custom URL</th>
                                                    <td>
                                                        <input ref={input => { this.urlInput = input }} type='text' placeholder='Enter URL' />
                                                        <button onClick={() => this.setState({ url: this.urlInput.value })}>Load</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="col-md-4">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>url</th>
                                                    <td className={!url ? 'faded' : ''}>
                                                        {(url instanceof Array ? 'Multiple' : url) || 'null'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>playing</th>
                                                    <td>{playing ? 'true' : 'false'}</td>
                                                </tr>
                                                <tr>
                                                    <th>volume</th>
                                                    <td>{volume.toFixed(3)}</td>
                                                </tr>
                                                <tr>
                                                    <th>played</th>
                                                    <td>{played.toFixed(3)}</td>
                                                </tr>
                                                <tr>
                                                    <th>loaded</th>
                                                    <td>{loaded.toFixed(3)}</td>
                                                </tr>
                                                <tr>
                                                    <th>duration</th>
                                                    <td><Duration seconds={duration} /></td>
                                                </tr>
                                                <tr>
                                                    <th>elapsed</th>
                                                    <td><Duration seconds={duration * played} /></td>
                                                </tr>
                                                <tr>
                                                    <th>remaining</th>
                                                    <td><Duration seconds={duration * (1 - played)} /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            {/* player wrapper end */}
                            <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">

                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 plr-2-5">
                                    <div className="para-title">কালেমা</div>
                                    {
                                        this.state.kalima_loading ?
                                            <div className="loading section-loading"> <i class="fas fa-spinner fa-spin"></i> </div>
                                            :
                                            <div className="nano">
                                                <div className="nano-content">
                                                    <div className="para-item">
                                                        <Scrollbars className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                                                            <ul>
                                                                {
                                                                    this.state.kalimaList.length > 0 ?
                                                                        <Fragment>
                                                                            {
                                                                                this.state.kalimaList.map((kalimaList_single, index) => {
                                                                                    return (
                                                                                        <li onClick={() => this.updateActiveKalima(kalimaList_single.sl)} key={`kalima_no_${kalimaList_single.id}`} className={classnames(null, { 'sellect': parseInt(kalimaList_single.sl) === parseInt(this.state.activeKalima) })}>
                                                                                            <div className="para-number">
                                                                                                <span>{kalimaList_single.sl_bn}</span>
                                                                                            </div>
                                                                                            <div className="para-number-no">
                                                                                                {kalimaList_single.title_bn}
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Fragment>
                                                                        :
                                                                        <div className="error-msg">{this.state.para_error}</div>
                                                                }
                                                            </ul>
                                                        </Scrollbars>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>

                                <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9 plr-2-5">
                                    <div className="para-title-box">
                                        <div className="para-title"> কালেমা:<span> {this.state.currently_playing_kalima_name_bn_sort} </span></div>
                                    </div>
                                    {
                                        this.state.kalima_loading ?
                                            <div className="loading section-loading"> <i class="fas fa-spinner fa-spin"></i> </div>
                                            :
                                            <div className="nano">
                                                <div className="nano-content">
                                                    <div className="para-item ayata-item kalema-item-det">
                                                        <Scrollbars className="CusScrollbars" id="CusScrollbars1" autoHide style={{ height: '100%' }} ref={this.scrollbars1}>
                                                            <ul>
                                                                {
                                                                    this.state.kalimaList.length > 0 ?
                                                                        <Fragment>
                                                                            {
                                                                                this.state.kalimaList.map((kalimaList_single, index) => {
                                                                                    return (
                                                                                        <li key={`kalima_id_${kalimaList_single.id}`} className={classnames('sellect', { 'd-none': parseInt(kalimaList_single.sl) != parseInt(this.state.activeKalima) })}>
                                                                                            <div className="ayata-item-box">
                                                                                                <div className="ayata-title" dir="rtl" lang="ar">{kalimaList_single.kalima}</div>
                                                                                                <div className="ayata-item-start">
                                                                                                    <ul>
                                                                                                        <li>
                                                                                                            <div className="kalema-bengali-pronunciation">
                                                                                                                বাংলা উচ্চারণ - <br />
                                                                                                                {kalimaList_single.pronunciation_bn}
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="kalema-bengali-meaning">
                                                                                                                বাংলা অর্থ -  <br />
                                                                                                                {kalimaList_single.meaning_bn}
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="kalema-english-meaning">
                                                                                                                <b> English </b> <br />
                                                                                                                {kalimaList_single.meaning_en}
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>


                                                                                                {
                                                                                                    (this.state.playing_mode == 'a') || (this.state.playing_mode == 'ab') ? (this.state.playing && (this.state.currently_playing_kalima_sl === parseInt(kalimaList_single.sl)) ?
                                                                                                        <button type="button" onClick={this.handlePlayPause} className="pause-button">
                                                                                                            <span></span>
                                                                                                        </button>
                                                                                                        :
                                                                                                        <Fragment>
                                                                                                            {
                                                                                                                ((this.state.currently_playing_kalima_sl === parseInt(kalimaList_single.sl)) && (this.state.duration > 0)) ?
                                                                                                                    < button type="button" onClick={this.handlePlayPause} className="play-button">
                                                                                                                        <span></span>
                                                                                                                    </button>
                                                                                                                    :
                                                                                                                    <button type="button" onClick={() => this.load(kalimaList_single.ar_audio, kalimaList_single.sl)} className="play-button">
                                                                                                                        <span></span>
                                                                                                                    </button>
                                                                                                            }
                                                                                                        </Fragment>

                                                                                                    )
                                                                                                        : ''
                                                                                                }

                                                                                                {
                                                                                                    (this.state.playing_mode == 'b') ? (this.state.playing && (this.state.currently_playing_kalima_sl === parseInt(kalimaList_single.sl)) ?
                                                                                                        < button type="button" onClick={this.handlePlayPause} className="pause-button">
                                                                                                            <span></span>
                                                                                                        </button>
                                                                                                        :
                                                                                                        <Fragment>
                                                                                                            {
                                                                                                                ((this.state.currently_playing_kalima_sl === parseInt(kalimaList_single.sl)) && (this.state.duration > 0)) ?
                                                                                                                    < button type="button" onClick={this.handlePlayPause} className="play-button">
                                                                                                                        <span></span>
                                                                                                                    </button>
                                                                                                                    :
                                                                                                                    <button type="button" onClick={() => this.load(kalimaList_single.bn_audio, kalimaList_single.sl)} className="play-button">
                                                                                                                        <span></span>
                                                                                                                    </button>
                                                                                                            }
                                                                                                        </Fragment>

                                                                                                    )

                                                                                                        : ''
                                                                                                }

                                                                                                {/* {
                                                                                                this.state.currently_playing_kalima === parseInt(kalimaList_single.sl) ?
                                                                                                    <button type="button" onClick={() => this.load('https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3')} className="pause-button">
                                                                                                        <span></span>
                                                                                                    </button>
                                                                                                    :
                                                                                                    <button type="button" onClick={() => this.load('https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3')} className="play-button">
                                                                                                        <span></span>
                                                                                                    </button>
                                                                                            } */}


                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Fragment>
                                                                        :
                                                                        <div className="error-msg">{this.state.sura_error}
                                                                        </div>
                                                                }
                                                            </ul>
                                                        </Scrollbars>
                                                    </div>

                                                </div>
                                            </div>
                                    }
                                </div>

                                <div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className="full-play">
                                        <div className="full-play-inner">
                                            {
                                                this.state.player_loading ?
                                                    <div className="loading player-loading"> <i class="fas fa-spinner fa-spin"></i> </div>
                                                    :
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="full-play-surah">
                                                                <div className="para-number">
                                                                    <span>{this.state.currently_playing_kalima_no_bn}</span>
                                                                </div>
                                                                <div className="para-number-no full-play-name">
                                                                    {this.state.currently_playing_kalima_name_bn}
                                                                    {/* <span>{this.state.currently_playing_kalima_name_bn}</span> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="full-play-btn-area">
                                                                <ul>
                                                                    <li>
                                                                        {
                                                                            this.state.currently_playing_prev_kalima_data != '' ?
                                                                                <Fragment>
                                                                                    {
                                                                                        ((this.state.playing_mode == 'ab') || (this.state.playing_mode == 'a')) ?
                                                                                            <button className="full-play-btn-left" onClick={() => this.load(this.state.currently_playing_prev_kalima_data.audio_url, this.state.currently_playing_prev_kalima_data.sl)}></button>
                                                                                            :
                                                                                            <button className="full-play-btn-left" onClick={() => this.load(this.state.currently_playing_prev_kalima_data.audio_url, this.state.currently_playing_prev_kalima_data.sl)}></button>
                                                                                    }
                                                                                </Fragment>
                                                                                :
                                                                                <button className="full-play-btn-left"></button>
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        <div className="pause-dropdown-block">
                                                                            {
                                                                                playing ?
                                                                                    <button type="button" onClick={this.handlePlayPause} className="pause-button">
                                                                                        <span></span>
                                                                                    </button>
                                                                                    :
                                                                                    <button type="button" onClick={this.handlePlayPause} className="full-play-btn-play-pause"></button>
                                                                            }

                                                                            <Bootstrap.DropdownButton className="dropdown-basic-button" id="dropdown-basic-button" title={this.state.playing_mode == 'a' ? 'আরবি তেলাওয়াত ' : (this.state.playing_mode == 'b' ? 'বাংলা অর্থ ' : 'আরবি তেলাওয়াত এবং বাংলা অর্থ ')} drop='up'>
                                                                                {/* <Bootstrap.Dropdown.Item href="#" onClick={(e) => this.changePlayingMode(e, 'b')}>বাংলা অর্থ </Bootstrap.Dropdown.Item> */}
                                                                                <Bootstrap.Dropdown.Item href="#" onClick={(e) => this.changePlayingMode(e, 'a')}>আরবি তেলাওয়াত </Bootstrap.Dropdown.Item>
                                                                                {/* <Bootstrap.Dropdown.Item href="#" onClick={(e) => this.changePlayingMode(e, 'ab')}>আরবি তেলাওয়াত এবং বাংলা অর্থ </Bootstrap.Dropdown.Item> */}
                                                                            </Bootstrap.DropdownButton>
                                                                        </div>


                                                                    </li>
                                                                    <li>
                                                                        {
                                                                            this.state.currently_playing_next_kalima_data != '' ?
                                                                                <Fragment>
                                                                                    {
                                                                                        ((this.state.playing_mode == 'ab') || (this.state.playing_mode == 'a')) ?
                                                                                            <button className="full-play-btn-right" onClick={() => this.load(this.state.currently_playing_next_kalima_data.audio_url, this.state.currently_playing_next_kalima_data.sl)}></button>
                                                                                            :
                                                                                            <button className="full-play-btn-right" onClick={() => this.load(this.state.currently_playing_next_kalima_data.audio_url, this.state.currently_playing_next_kalima_data.sl)}></button>
                                                                                    }
                                                                                </Fragment>
                                                                                :
                                                                                <button className="full-play-btn-right"></button>
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        <span><Duration seconds={duration * played} /> / <Duration seconds={duration} /></span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="play-normal">
                                                                <div className="speed-control">
                                                                    <Bootstrap.DropdownButton className="dropdown-basic-button" id="dropdown-basic-button" title="" drop='up'>
                                                                        <Bootstrap.Dropdown.Item href="#"> Speed </Bootstrap.Dropdown.Item>
                                                                        <Bootstrap.Dropdown.Item href="#" onClick={(e) => this.handleSetPlaybackRate(e, 0.25)}>0.25 </Bootstrap.Dropdown.Item>
                                                                        <Bootstrap.Dropdown.Item href="#" onClick={(e) => this.handleSetPlaybackRate(e, 0.5)}>0.5 </Bootstrap.Dropdown.Item>
                                                                        <Bootstrap.Dropdown.Item href="#" onClick={(e) => this.handleSetPlaybackRate(e, 0.75)}>0.75 </Bootstrap.Dropdown.Item>
                                                                        <Bootstrap.Dropdown.Item href="#" onClick={(e) => this.handleSetPlaybackRate(e, 1)}>Normal</Bootstrap.Dropdown.Item>
                                                                        <Bootstrap.Dropdown.Item href="#" onClick={(e) => this.handleSetPlaybackRate(e, 1.25)}>1.25 </Bootstrap.Dropdown.Item>
                                                                        <Bootstrap.Dropdown.Item href="#" onClick={(e) => this.handleSetPlaybackRate(e, 1.5)}>1.5 </Bootstrap.Dropdown.Item>
                                                                        <Bootstrap.Dropdown.Item href="#" onClick={(e) => this.handleSetPlaybackRate(e, 2.0)}>2.0 </Bootstrap.Dropdown.Item>
                                                                    </Bootstrap.DropdownButton>
                                                                    <div className="speed-control-text">
                                                                        <span>
                                                                            {
                                                                                this.state.playbackRate === 1 ?
                                                                                    'Normal'
                                                                                    :
                                                                                    this.state.playbackRate + 'x'
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.state.loop ?
                                                                <button className="active-loop" onClick={this.handleToggleLoop}></button>
                                                                :
                                                                <button className="no-Loop" onClick={this.handleToggleLoop}></button>
                                                            }

                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Fragment>
                }
            </Fragment>
        );
    }
}

export default withRouter(ParaKrom);