import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';
import { NavLink } from 'react-router-dom'

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';
import history from '../../history'


export class Hadiths extends Component {

    hadithScrollbars = React.createRef();
    hadithSearchScrollbars = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            hadith_name: 'বুখারী শরীফ',
            hadithList: [],
            query_string: '',
            isEnded: false,
            error: '',
            offset: 0,
            limit: 10,
        }
    }

    componentDidMount() {
        this.getHadithData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps != this.props) {
            this.getHadithData()
        }
    }

    getHadithData = (query = '') => {
        if (!this.state.isEnded) {
            this.setState({ loading: true, isEnded: false })
            AJAX_PUBLIC_REQUEST("POST", "hadith/topic/list", {
                hadith_id: this.props.match.params.hadith_id === 'search' ? '' : this.props.match.params.hadith_id,
                query_string: query,
                offset: this.state.offset,
                limit: this.state.limit
            }).then(results => {
                if (results.type === "success") {
                    this.setState({
                        hadithList: results.data,
                        loading: false,
                        offset: this.state.offset + this.state.limit,
                        isEnded: results.data.length > 0 ? false : true,
                        error: results.success
                    });
                } else {
                    this.setState({
                        error: results.error,
                        loading: false,
                        isEnded: false,
                    })
                }
            });
        }
    }

    searchHadithData = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        if ((e.target.value == '') || (e.target.value.length > 2)) {
            this.getHadithData(e.target.value)
        }
    }

    goToSearchDetails = (e) => {
        e.preventDefault()
        if (this.props.match.params.hadith_id === 'search') {
            history.push('/search/hadiths/all?query=' + this.state.query_string)
        } else {
            history.push('/search/hadiths/' + this.props.match.params.hadith_id + '?query=' + this.state.query_string)
        }
    }

    render() {
        const hadith_id = this.props.match.params.hadith_id
        return (
            <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                <div className="col-sm-12 col-md-12">
                    <div className="download-section new-hadith-search">
                        {
                            hadith_id == 'search' ?
                                <div className="download-section-title text-left">হাদিসে র্সাচ</div>
                                :
                                <div className="hadith-name-list">
                                    <ul>
                                        <li>হাদিস সমুহ</li>
                                        <li><i className="icofont-simple-right"></i></li>
                                        <li>{this.state.hadith_name}</li>
                                    </ul>
                                </div>
                        }


                        <div className="download-section-inner max-width-100">
                            <div className="new-hadith-search-form">
                                <Bootstrap.Form>
                                    <Bootstrap.Form.Control type="text" placeholder="হাদিসে সার্চ করুন" onChange={this.searchHadithData} name="query_string" value={this.state.query_string} />
                                    <Bootstrap.Button variant="secondary" className="search-custom-btn-new" onClick={this.goToSearchDetails}>
                                        <i className="fas fa-search"></i>
                                    </Bootstrap.Button>
                                </Bootstrap.Form>
                            </div>
                            <div className="nano">
                                <div className="nano-content">
                                    <div className="para-item download-item">
                                        {
                                            hadith_id == 'search' ?
                                                <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.hadithSearchScrollbars}>
                                                    <div className="new-hadith-search-content">
                                                        {
                                                            this.state.loading ?
                                                                <div className="loading section-loading"> <i className="fas fa-spinner fa-spin"></i> </div>
                                                                :
                                                                <ul>
                                                                    {
                                                                        this.state.hadithList.length > 0 ?
                                                                            <Fragment>
                                                                                {
                                                                                    this.state.hadithList.map((hadithList_single, hadithList_index) => {
                                                                                        return (
                                                                                            <li key={`hadiths-${hadithList_single.hadith_id}-${hadithList_single.topic_id}-${hadithList_index}`}>
                                                                                                <NavLink to={`/hadiths/${hadithList_single.hadith_id}/${hadithList_single.topic_id}`}>
                                                                                                    <div>
                                                                                                        <strong>নামায</strong>
                                                                                                        <span>সহীহ বুখারী</span>
                                                                                                        <span>১২৩ টি হাদিস</span>
                                                                                                    </div>
                                                                                                </NavLink>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Fragment>
                                                                            :
                                                                            <li>
                                                                                <div className="no_data_found">{this.state.error}</div>
                                                                            </li>
                                                                    }
                                                                </ul>

                                                        }
                                                    </div>
                                                </Scrollbars>
                                                :
                                                <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.hadithScrollbars}>
                                                    <div className="new-hadith-search-content new-bukhari-hadiths">
                                                        {
                                                            this.state.loading ?
                                                                <div className="loading section-loading"> <i className="fas fa-spinner fa-spin"></i> </div>
                                                                :
                                                                <ul>
                                                                    {
                                                                        this.state.hadithList.length > 0 ?
                                                                            <Fragment>
                                                                                {
                                                                                    this.state.hadithList.map((hadithList_single, hadithList_index) => {
                                                                                        return (
                                                                                            <li key={`hadiths-${hadithList_single.hadith_id}-${hadithList_single.topic_id}-${hadithList_index}`}>
                                                                                                <NavLink to={`/hadiths/${hadithList_single.hadith_id}/${hadithList_single.topic_id}`}>
                                                                                                    <div>
                                                                                                        <strong>{hadithList_single.title}</strong>
                                                                                                        <span>হাদিস সংখ্যা {hadithList_single.total_bangla} | নম্বর {hadithList_single.hadith_range}</span>
                                                                                                    </div>
                                                                                                </NavLink>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Fragment>
                                                                            :
                                                                            <li>
                                                                                <div className="no_data_found">{this.state.error}</div>
                                                                            </li>
                                                                    }
                                                                </ul>
                                                        }
                                                    </div>
                                                </Scrollbars>
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default Hadiths
