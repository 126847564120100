import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';

import contactPageValidation from '../../Validations/contactPageValidation';
import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';
import ReCAPTCHA from "react-google-recaptcha";

class ContactUs extends PureComponent {
    scrollbars = React.createRef();
    recaptchaRef = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            name: '',
            phone: '',
            email: '',
            message: '',
            site_key: '6LdvaskZAAAAAHJTo5Fp0VhLusSBfbUlZCXTQyja',
            secret_key: '6LdvaskZAAAAAM4xd-b_mf504ynWn8Wp4zz1HIhL',
            recaptcha_token: '',

            load_recaptcha: false,
            success_msg: '',
            error: '',
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
        }
        document.title = "Contact Us - Al Quran Technobd";
    }


    componentDidMount() {
        // this.getGoogleRecaptchaKey();
        this.setState({ loading: false, load_recaptcha: true })
    }

    getGoogleRecaptchaKey = () => {
        AJAX_PUBLIC_REQUEST("POST", "getGoogleRecaptchaKey", {}).then(results => {
            if (results.type === "success") {
                const data = results.data;
                this.setState({
                    site_key: data.site_key,
                    secret_key: data.secret_key,
                    loading: false,
                    load_recaptcha: true
                });
            } else {
                this.setState({
                    error: results.error,
                    loading: false,
                    load_recaptcha: false
                })
            }
        });
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        const data = {
            name: this.state.name,
            phone: this.state.phone,
            email: this.state.email,
            message: this.state.message,
            recaptcha_token: this.state.recaptcha_token,
        }
        const val_return = contactPageValidation(data);
        this.setState(val_return);
        if (val_return.isValid) {
            if (this.state.recaptcha_token != '') {
                this.setState({ errors: {}, error: '', success_msg: '', isLoading: true });
                AJAX_PUBLIC_REQUEST("POST", "saveContact", data).then(results => {
                    if (results.type === "success") {
                        const category = results.data;
                        this.setState({
                            isLoading: false,
                            isFormValid: true,

                            name: '',
                            phone: '',
                            email: '',
                            message: '',
                            captcha: '',
                            success_msg: results.success
                        });
                        setTimeout(() => {
                            const url_redirect = window.location.href;
                            window.location.href = url_redirect;
                        }, 3000)
                    } else {
                        this.setState({
                            error: results.error,
                            success_msg: '',
                            errors: results.error,
                            isLoading: false,
                            isFormValid: false,
                        })
                    }
                });
            } else {
                this.setState({
                    error: 'Invalid Captcha',
                    isLoading: false,
                    isFormValid: false,
                })
            }

        } else {
            document.querySelector("body").scrollIntoView();
        }
    }

    onChange = (value) => {
        this.setState({ recaptcha_token: value })
    }



    render() {
        const { errors } = this.state;
        return (
            <Fragment>
                <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="contact-block-wrap">
                            <div className="para-title text-center">যোগাযোগ</div>
                            {
                                this.state.category_loading ?
                                    <div className="loading section-loading"> <i className="fas fa-spinner fa-spin"></i> </div>
                                    :
                                    <div className="para-item contact-block">

                                        <div className="nano">
                                            <div className="nano-content">
                                                {
                                                    this.state.error != '' ?
                                                        <div className="error-msg text-danger">{this.state.error}</div>
                                                        : ''
                                                }
                                                {
                                                    this.state.success_msg != '' ?
                                                        <div className="success-msg text-success">{this.state.success_msg}</div>
                                                        : ''
                                                }
                                                <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                                                    <form onSubmit={this.onSubmit}>
                                                        <div className="form-group">
                                                            <label htmlFor="name" className={classnames(null, { 'alquran_error_label': errors.name })}>{errors.name ? 'আপনার নাম লিখুন।' : ''}</label>
                                                            <input type="text" className={classnames('form-control', { 'alquran_error_input': errors.name })} name="name" id="name" value={this.state.name} placeholder="নাম" onChange={this.changeHandler} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="name" className={classnames(null, { 'alquran_error_label': errors.name })}>{errors.email ? 'আপনার ইমেইল লিখুন।' : ''}</label>
                                                            <input type="email" className={classnames('form-control', { 'alquran_error_input': errors.email })} name="email" id="email" value={this.state.email} placeholder="ইমেইল" onChange={this.changeHandler} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="name" className={classnames(null, { 'alquran_error_label': errors.name })}>{errors.phone ? 'আপনার মোবাইল নম্বর লিখুন।' : ''}</label>
                                                            <input type="text" className={classnames('form-control', { 'alquran_error_input': errors.phone })} name="phone" id="phone" value={this.state.phone} placeholder="মোবাইল নম্বর" onChange={this.changeHandler} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="name" className={classnames(null, { 'alquran_error_label': errors.name })}>{errors.message ? 'আপনার মেসেজ লিখুন।' : ''}</label>
                                                            <textarea className={classnames('form-control', { 'alquran_error_textarea': errors.message })} name="message" id="message" value={this.state.message} placeholder="মেসেজ" onChange={this.changeHandler}></textarea>
                                                        </div>
                                                        <div className="form-group">
                                                            {
                                                                this.state.load_recaptcha ?
                                                                    <ReCAPTCHA
                                                                        ref={this.recaptchaRef}
                                                                        sitekey={this.state.site_key}
                                                                        onChange={this.onChange}
                                                                    />
                                                                    : ''
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" className="btn contact-btn" disabled={this.state.isLoading}>{this.state.isLoading ? 'অপেক্ষা করুন ...' : 'সাবমিট'}</button>
                                                        </div>
                                                    </form>
                                                </Scrollbars>
                                            </div>
                                        </div>

                                    </div>
                            }
                        </div>
                    </div>
                    <div>
                    </div>

                </div>


            </Fragment>
        );
    }
}

export default withRouter(ContactUs);