import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';

class Disclaimer extends PureComponent {

  scrollbars = React.createRef();

  render() {
    document.title = "Disclaimer - Al Quran Technobd";
    return (
      <Fragment>
        <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
          <div className="col-sm-12 col-md-12">
            <div className="download-section">
              <div className="download-section-title">ডিসক্লেইমার</div>
              <div className="download-section-inner">
                <div className="nano nano-dua">
                  <div className="nano-content">
                    <div className="para-item download-item">
                      <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                        <div className="disclaimer">
                          <p>
                            Mostly, but not entirely. Alquran articles incorporate public domain or Creative Commons text from various sources,. The source on which such articles are based is always clearly noted in the “Sources” or “References” section. Articles on this site that are based on public domain texts are not automatically in the public domain. As mentioned above, Check your local laws or an attorney for all the important details.
                          </p>
                          <p>
                            <strong>General</strong>
                    This Agreement, sets forth the terms and conditions that apply to use of this site Alquran. Com , by a Subscriber. “Subscriber” means each person who establishes or accesses a connection for access to and use of the Alquran.com Sites.
                          </p>
                          <p>
                            <strong>Subscriber Conduct</strong>
                    Subscriber shall use the Sites for lawful purposes only. Subscriber shall not post or transmit through the Sites any material which violates or infringes in any way upon the rights of others, which is unlawful, threatening, abusive, defamatory, invasive of privacy or publicity rights, vulgar, obscene, profane or otherwise objectionable, which encourages conduct that would constitute a criminal offence, give right to civil liability or otherwise violate any law.
                          </p>
                          <p>
                            <strong>Disclaimer of Alquran.com Warranty: Limitation of Liability</strong>
                          </p>
                          <p>
                            Subscriber expressly agrees that use of Sites are at Subscriber’s sole risk. Neither the Alquran.com , its affiliates nor any of their respective employees, agents, third party content providers or licensors warrant that Sites will be uninterrupted or error free; nor do they make any warranty as to the results that may be obtained from use of Sites, or as to the accuracy, reliability or content of any information, service, or merchandise provided through Sites.
                          </p>
                          <p>
                            Sites Alquran.com is provided on an “As Is” basis without warranties of any kind, either express or implied, including, but not limited to, warranties of title or implied warranties of merchantability or fitness for a particular purpose, other than those warranties which are implied by and incapable of exclusion, restriction or modification under the laws applicable to this agreement.
                          </p>
                          <p>
                            This disclaimer of liability applies to any damages or injury, caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorized access to, alteration of, or use of record, whether for breach of contract, tortious behavior, negligence, or under any other cause of action. Subscriber specifically acknowledges that the Alquran.com is not liable for the defamatory, offensive or illegal conduct of other subscribers or third-parties in cases including but not limited to any interactive communication on or through the sites and that the risk of injury from the foregoing rests entirely with subscriber(s).
                          </p>
                          <p>
                            In addition to the terms set forth above neither, the Alquran.com , not its affiliates, information providers or content partners shall be liable regardless of the cause or duration, for any errors, inaccuracies, omissions, or other defects in, or untimeliness or unauthenticity of, the information contained. The Alquran.com , its affiliates, information providers or content partners shall have no liability for investment decisions based on the information provided. Neither, the Alquran.com , nor its affiliates, information providers or content partners warrant or guarantee the timeliness, sequence, accuracy or completeness of this information.
                            Adobe and the Adobe logo are either registered trademarks or trademarks of Adobe Systems Incorporated in the United States and/or other countries.
                          </p>
                        </div>
                      </Scrollbars>
                    </div>

                  </div>
                </div>
              </div>

            </div>

            <div>
            </div>

          </div>
        </div>

      </Fragment>
    );
  }
}

export default withRouter(Disclaimer);