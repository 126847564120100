import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';

class PageNotFound extends PureComponent {

    scrollbars = React.createRef();

    render() {
        document.title = "Disclaimer - Al Quran Technobd";
        return (
            <Fragment>
                <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                    <div className="col-sm-12 col-md-12">
                        <div className="download-section">
                            <div className="download-section-title">
                                <NavLink className="logo" to="/"> <img src={require('../../Assets/images/logo.png')} alt="" title="" /></NavLink>
                            </div>
                            <div className="download-section-inner">
                                <div className="nano nano-dua">
                                    <div className="nano-content">
                                        <div className="para-item download-item">
                                            <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                                                <div className="disclaimer">
                                                    <div className="page-not-found-content text-center">
                                                        <div className="page-not-found-thumb">
                                                            <img className="" src={require('../../Assets/images/404-1.png')} />
                                                        </div>
                                                        <div className="page-not-found-info">
                                                            <h1>দুঃখিত!</h1>
                                                            <p>আপনি যেই পৃষ্ঠাটি খুঁজছেন তা পাওয়া যায়নি।</p>
                                                            <NavLink to="/"> হোম পেইজ</NavLink>
                                                       </div>

                                                    </div>
                                                </div>
                                            </Scrollbars>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div>
                        </div>

                    </div>
                </div>

            </Fragment>
        );
    }
}

export default withRouter(PageNotFound);